import { Router } from '@reach/router'
import React from 'react'
import Alert from 'react-s-alert'

import RoutePrivate from 'components/RoutePrivate'
import SuspenseWrapper from 'components/SuspenseWrapper'
import paths from 'constants/paths'
import { BillsProvider } from 'contexts/BillsContext'
import AppLayout from 'app/components/AppLayout'

const PayLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Pay')))
const PaymentLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Payment')))
const PaymentMethodAddLazy = SuspenseWrapper(React.lazy(() => import('app/pages/PaymentMethodAdd')))
const PaymentMethodLazy = SuspenseWrapper(React.lazy(() => import('app/pages/PaymentMethod')))
const PaymentMethodsLazy = SuspenseWrapper(React.lazy(() => import('app/pages/PaymentMethods')))
const PaymentsLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Payments')))
const RecipientAddLazy = SuspenseWrapper(React.lazy(() => import('app/pages/RecipientAdd')))
const RecipientLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Recipient')))
const RecipientsLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Recipients')))
const SettingsLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Settings')))
const SupportLazy = SuspenseWrapper(React.lazy(() => import('app/pages/Support')))

export default function App() {
  return (
    <BillsProvider>
      <Alert effect="stackslide" position="top" timeout={5000} />
      <AppLayout>
        <Router>
          <RoutePrivate path={paths.PAYMENTS} component={PaymentsLazy} default />
          <RoutePrivate path={paths.PAY} component={PayLazy} />
          <RoutePrivate path={paths.PAYMENT} component={PaymentLazy} />
          <RoutePrivate path={paths.PAYMENT_METHOD} component={PaymentMethodLazy} />
          <RoutePrivate path={paths.PAYMENT_METHOD_ADD} component={PaymentMethodAddLazy} />
          <RoutePrivate path={paths.PAYMENT_METHODS} component={PaymentMethodsLazy} />
          <RoutePrivate path={paths.RECIPIENT} component={RecipientLazy} />
          <RoutePrivate path={paths.RECIPIENT_ADD} component={RecipientAddLazy} />
          <RoutePrivate path={paths.RECIPIENTS} component={RecipientsLazy} />
          <RoutePrivate path={paths.SETTINGS} component={SettingsLazy} />
          <RoutePrivate path={paths.SUPPORT} component={SupportLazy} />
        </Router>
      </AppLayout>
    </BillsProvider>
  )
}
