import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@fundrocket/common-web/components/Button'
import Display from '@fundrocket/common-web/components/Display'
import MediaObject from '@fundrocket/common-web/components/MediaObject'

import urls from 'constants/urls'
import { UserContext } from 'contexts/User'

import { ReactComponent as SignOutSvg } from './images/sign-out.svg'

export default class SignOutButton extends React.Component {
  static contextType = UserContext

  handleClick = () => {
    window.location = urls.SIGN_OUT
  }

  render() {
    const { className, ...props } = this.props

    if (!_.get(this, 'context.user')) {
      return null
    }

    return (
      <Button
        {...props}
        color="grey"
        level="text"
        title="You will stay logged into your Womply account"
        testid="sign-out-button"
        className={classNames('SignOutButton', className)}
        onClick={this.handleClick}>
        <MediaObject center size="small">
          <SignOutSvg />
          <>
            Sign Out
            <Display element="span" hide="mobile">
              {' '}
              from Bills
            </Display>
          </>
        </MediaObject>
      </Button>
    )
  }
}

SignOutButton.propTypes = {
  className: PropTypes.string,
}
