import React, { Suspense } from 'react'
import AppLayout from 'app/components/AppLayout'

/**
 * HOC which wraps a lazy loaded component with React suspense
 * @param {React.Component} ComponentToBeWrapped Lazy loaded component to be wrapped
 */
const SuspenseWrapper = (ComponentToBeWrapped) => (props) => (
  <Suspense fallback={<AppLayout loading />}>
    <ComponentToBeWrapped {...props} />
  </Suspense>
)

export default SuspenseWrapper
