import _ from 'lodash'

import AmexImage from 'images/networks/amex.svg'
import DinersClubImage from 'images/networks/diners.svg'
import DiscoverImage from 'images/networks/discover.svg'
import MastercardImage from 'images/networks/mastercard.svg'
import VisaImage from 'images/networks/visa.svg'

const MASK_LAST_LENGTH = 4

const NETWORKS = {
  amex: {
    name: 'Amex',
    image: AmexImage,
  },
  diners: {
    name: 'Diners',
    image: DinersClubImage,
  },
  discover: {
    name: 'Discover',
    image: DiscoverImage,
  },
  mastercard: {
    name: 'Mastercard',
    image: MastercardImage,
  },
  visa: {
    name: 'Visa',
    image: VisaImage,
  },
}

const formatMask = (mask) => `**${mask}`

const getPaymentMethodById = (paymentMethods, id) => paymentMethods.find((item) => item.id === id)

const getPaymentMethodCardExpirationDate = (dateMMYY) =>
  `${dateMMYY.slice(0, 2)}/${dateMMYY.slice(2)}`

const getPaymentMethodCardMask = (cardNumber) => cardNumber.slice(-MASK_LAST_LENGTH)
const getPaymentMethodCardMaskFormatted = (paymentMethod) => formatMask(paymentMethod.mask)

const getPaymentMethodCardName = (paymentMethod) =>
  paymentMethod.label ||
  `${getPaymentMethodCardNetwork(paymentMethod).name} ${formatMask(paymentMethod.mask)}`

const getPaymentMethodCardNetwork = (paymentMethod) =>
  NETWORKS[paymentMethod.card_type] || { name: _.capitalize(paymentMethod.card_type) }

const hasPaymentMethod = (paymentMethods) => !_.isEmpty(paymentMethods)

const hasPaymentMethodCardNetworkKnown = (paymentMethod) => NETWORKS[paymentMethod.card_type]

export default {
  formatMask,
  getPaymentMethodById,
  getPaymentMethodCardExpirationDate,
  getPaymentMethodCardMask,
  getPaymentMethodCardMaskFormatted,
  getPaymentMethodCardName,
  getPaymentMethodCardNetwork,
  hasPaymentMethod,
  hasPaymentMethodCardNetworkKnown,
}
