import PropTypes from 'prop-types'
import React from 'react'

import notifications from '@fundrocket/common-web/libs/notifications'

import { UserContext } from 'contexts/User'
import BillsService from 'services/BillsService'

const billsService = new BillsService()

const BillsContext = React.createContext()
const { Consumer: BillsConsumer, Provider } = BillsContext

class BillsProvider extends React.Component {
  static contextType = UserContext

  state = {
    paymentMethodCards: [],
    payments: [],
    recipients: [],
  }

  componentDidMount() {
    this.getPaymentMethodCards()
    this.getPayments()
    this.getRecipients()
  }

  getPaymentMethodCards = async () => {
    try {
      const paymentMethodCards = await billsService.getPaymentMethods()
      this.setState({ paymentMethodCards })
    } catch {
      if (this.context.user) {
        notifications.notify({
          level: 'warning',
          message: 'We’re having trouble loading your payment methods. Please try again later.',
        })
      }
    }
  }

  getPayments = async () => {
    try {
      const payments = await billsService.getPayments()
      this.setState({ payments })
    } catch {
      if (this.context.user) {
        notifications.notify({
          level: 'warning',
          message: 'We’re having trouble loading your payments. Please try again later.',
        })
      }
    }
  }

  getRecipients = async () => {
    try {
      const recipients = await billsService.getRecipients()
      this.setState({ recipients })
    } catch {
      if (this.context.user) {
        notifications.notify({
          level: 'warning',
          message: 'We’re having trouble loading your recipients',
        })
      }
    }
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          updatePaymentMethodCards: this.getPaymentMethodCards,
          updatePayments: this.getPayments,
          updateRecipients: this.getRecipients,
        }}>
        {this.props.children}
      </Provider>
    )
  }
}

BillsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BillsContext
export { BillsConsumer, BillsProvider }
