import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

//import Button from '@fundrocket/common-web/components/Button'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Container from '@fundrocket/common-web/components/Container'
//import Display from '@fundrocket/common-web/components/Display'
import ErrorBoundary from '@fundrocket/common-web/components/ErrorBoundary'
import LoadingIndicator from '@fundrocket/common-web/components/LoadingIndicatorWomply'
//import Text from '@fundrocket/common-web/components/Text'
// import View from '@fundrocket/common-web/components/View'

import Head from 'components/Head'
import Layout from 'components/Layout'
//import Link from 'components/Link'
import SignOutButton from 'components/SignOutButton'
//import paths from 'constants/paths'
import urls from 'constants/urls'
import BillsContext from 'contexts/BillsContext'

import styles from './AppLayout.module.scss'
//import { ReactComponent as LogoSvg } from './images/logo.svg'
//import { ReactComponent as LogoSmallSvg } from './images/logo---small.svg'
//import { ReactComponent as MenuSvg } from './images/menu.svg'
//import { ReactComponent as AvatarSvg } from './images/icons/person.svg'
//import { ReactComponent as CreditCardSvg } from './images/icons/card.svg'
//import { ReactComponent as ListSvg } from './images/icons/history.svg'
//import { ReactComponent as SupportSvg } from './images/icons/question.svg'

export default class AppLayout extends React.Component {
  static contextType = BillsContext

  state = {
    showDropdown: false,
  }

  componentDidMount() {
    document.addEventListener('click', this.hideDropdown)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdown)
  }

  hideDropdown = (e) => {
    if (e.target.getAttribute('id') === 'hamburger-menu') {
      return
    }
    this.setState({ showDropdown: false })
  }

  handleMenuButtonClick = () => {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }))
  }

  renderBody() {
    const { children, loading } = this.props

    if (loading) {
      return <LoadingIndicator />
    }

    //const navPrimary = [
    //  {
    //    copy: 'Payment history',
    //    to: paths.PAYMENTS,
    //    icon: ListSvg,
    //    testid: 'nav--payment-history',
    //  },
    //  {
    //    copy: 'Payment methods',
    //    to: paths.PAYMENT_METHODS,
    //    icon: CreditCardSvg,
    //    testid: 'nav--payment-methods',
    //  },
    //  {
    //    copy: 'Recipients',
    //    to: paths.RECIPIENTS,
    //    icon: AvatarSvg,
    //    testid: 'nav--recipients',
    //  },
    //  //{
    //  //  copy: 'Support',
    //  //  to: paths.SUPPORT,
    //  //  icon: SupportSvg,
    //  //  testid: 'nav--support',
    //  //},
    //]

    //const navSecondary = this.state.showDropdown && (
    //  <Text align="right" element="div" weight="bold" className={styles.dropdown}>
    //    <Container>
    //      {navPrimary.map((item) => (
    //        <Link
    //          to={item.to}
    //          block
    //          decorated={false}
    //          key={item.copy}
    //          weight="inherit"
    //          className={classNames(
    //            styles['nav-secondary--item'],
    //            styles['nav-secondary--item---primary']
    //          )}>
    //          {item.copy}
    //        </Link>
    //      ))}
    //      <Link
    //        to={paths.SUPPORT}
    //        block
    //        decorated={false}
    //        weight="inherit"
    //        className={styles['nav-secondary--item']}>
    //        Support
    //      </Link>
    //      <SignOutButton
    //        level="text"
    //        className={classNames(styles['nav-secondary--item'], styles['sign-out-button'])}
    //      />
    //    </Container>
    //  </Text>
    //)

    return (
      <div className={styles.inner}>
        {/*
        <Text color="white" element="div" className={styles.sidebar}>
          <Container size="large" flush className={styles['sidebar--inner']}>
            <Text align="center" element="div" className={styles['sidebar--logo']}>
              <Display hide="desktop">
                <LogoSvg />
              </Display>
              <Display show="desktop">
                <LogoSmallSvg className={styles['sidebar--logo--small']} />
              </Display>
            </Text>
            <Text size="large" className={styles.nav} block>
              <Button
                to={paths.PAY}
                level="primary"
                size="small"
                testid="nav--pay-button"
                className={styles['pay-button']}>
                <Display hide="desktop">Make a payment</Display>
                <Display show="desktop">Pay</Display>
              </Button>
              {navPrimary.map((item) => (
                <Link
                  to={item.to}
                  block
                  decorated={false}
                  key={item.copy}
                  partiallyActive
                  className={styles['nav--item--icon']}
                  activeClassName={styles['nav--item--icon---active']}
                  testid={item.testid}>
                  <Display hide="desktop">
                    <Text weight="bold">{item.copy}</Text>
                  </Display>
                  <Display show="desktop">
                    <item.icon title={item.copy} className={styles['nav--item--icon']} />
                  </Display>
                </Link>
              ))}
              <Display show="mobile">
                <button onClick={this.handleMenuButtonClick} id="hamburger-menu">
                  <MenuSvg />
                </button>
                {navSecondary}
              </Display>
            </Text>
            <Text element="nav" weight="semibold" className={styles['nav-secondary']}>
              <Link
                to={paths.SUPPORT}
                block
                decorated={false}
                weight="inherit"
                testid="nav--support"
                className={styles['nav-secondary--item']}>
                Support
              </Link>
              <SignOutButton
                level="text"
                className={classNames(styles['nav-secondary--item'], styles['sign-out-button'])}
              />
            </Text>
          </Container>
        </Text>
        */}
        <div className={styles.main}>
          <Container full>
            <Buttons align="right">
              <SignOutButton level="text" className={styles['sign-out-button']} />
            </Buttons>
          </Container>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Layout className={classNames('AppLayout', styles.this)}>
        <Head preconnect={[urls.NMI_CDN]} />
        {this.renderBody()}
      </Layout>
    )
  }
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
}
