import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import RestApiService from '@fundrocket/common/services/RestApiService'
import urls from 'constants/urls'

const addressDataMap = {
  verifyRecipient: {
    endpoint: process.env.GATSBY_SMARTYSTREETS_URL || `${urls.SMARTY_STREETS}/street-address`,
    bodyAsForm: true,
    model: (model) => model.data,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    extraAttributes: {
      key: process.env.GATSBY_SMARTYSTREETS_KEY,
      match: 'invalid',
    },
  },
}

export default class SmartyStreetsApiService extends RestApiService {
  constructor() {
    super(addressDataMap)
  }

  setHeaders(restMap = {}) {
    try {
      restMap.headers = { ...(restMap.headers || {}) }
    } catch (error) {
      reporter.trackError(error)
    }
  }

  verifyAddress = (data) =>
    this.get('verifyRecipient', { ...data, ...this.restMap.verifyRecipient.extraAttributes })
}
