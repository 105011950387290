import formatPhone from '@fundrocket/common/libs/format-phone'

import urls from 'constants/urls'
import RestApiService from 'services/RestApiService'
import paymentMethods from 'libs/payment-methods'
import AddressService from 'services/AddressService'

const billsDataMap = {
  payment: {
    endpoint: urls.API_PAYMENT,
    bodyAsForm: false,
    model: (model) => model.data,
    headers: {
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
    },
  },
  paymentMethod: {
    endpoint: urls.API_PAYMENT_METHOD,
    bodyAsForm: false,
    model: (model) => model.data,
    headers: {
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
    },
  },
  recipient: {
    endpoint: urls.API_RECIPIENT,
    bodyAsForm: false,
    model: (model) => model.data,
    headers: {
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
    },
  },
}

export default class BillsService extends RestApiService {
  constructor() {
    super(billsDataMap)
    this.addressService = new AddressService()
  }

  getPayments = () => this.get('payment')

  payment = (data) => this.post('payment', {}, data)

  cancelPayment = (id) => this.patch('payment', { append: id }, { status: 'CANCELLED' })

  getPaymentMethods = () => this.get('paymentMethod')

  addPaymentMethod = (data) =>
    this.post(
      'paymentMethod',
      {},
      {
        billing_address1: data.address,
        billing_address2: data.address2,
        billing_city: data.city,
        billing_company: data.company,
        billing_firstname: data.firstName,
        billing_lastname: data.lastName,
        billing_postal_code: data.zipCode,
        billing_state: data.state,
        card_expiry: data.card.exp,
        card_type: data.card.type,
        label: data.cardLabel,
        mask: paymentMethods.getPaymentMethodCardMask(data.card.number),
        payment_token: data.token,
      }
    )

  updatePaymentMethodLabel = (id, label) => this.patch('paymentMethod', { append: id }, { label })

  deletePaymentMethod = (id) => this.delete('paymentMethod', { append: id })

  getRecipients = () => this.get('recipient')

  addRecipient = (data) =>
    this.post(
      'recipient',
      {},
      {
        business_address: data.address1,
        business_apt_suite: data.address2,
        business_city: data.city,
        business_email: data.email,
        business_name: data.businessName,
        business_phone: formatPhone.api(data.phone),
        business_postal_code: data.zipCode,
        business_state_code: data.state,
        industry_category:
          (!['', undefined].includes(data.industry) && Number(data.industry)) || undefined,
        is_address_verified: data.addressVerified,
        label: data.label,
      }
    )

  verifyRecipient = (data) =>
    this.addressService.verifyRecipient({
      city: data.city,
      street: data.address,
      secondary: data.address2,
      state: data.state,
      zipcode: data.zipCode,
    })

  updateRecipientLabel = (id, label) => this.patch('recipient', { append: id }, { label })

  deleteRecipient = (id) => this.delete('recipient', { append: id })
}
